import {
  Box,
  ButtonBase,
  Typography,
  Checkbox as MaterialCheckbox,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLayoutEffect, useRef, useState } from "react";
import {
  Body,
  Padding,
  BoardPreview,
  Button,
  GroupedButton,
  IBoard,
  colors,
  IBoardStyle,
} from "@vestaboard/installables";
import { List } from "react-virtualized";
import { useWindowSize } from "../hooks/useWindowSize";

interface IMessage {
  characters: IBoard;
  id: string;
}

interface IMessagePicker {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onComplete: () => void;
  messages: IMessage[];
  selected: string[];
  setSelected: (selected: string[]) => void;
  noMessages: string;
  boardStyle: IBoardStyle;
}

const useStyles = makeStyles({
  container: {
    position: "fixed",
    bottom: 0,
    background: colors.grey,
    height: "100%",
    width: "100%",
    zIndex: 1001,
    left: 0,
    transition: "bottom 0.3s",
    display: "flex",
    flexDirection: "column",
  },
  cover: {
    top: 0,
    left: 0,
    zIndex: 999,
    width: "100%",
    height: "100%",
    position: "fixed",
    backgroundImage:
      "linear-gradient(to bottom, rgba(23, 24, 24, 0.59), rgba(23, 24, 24, 0.59))",
    backdropFilter: "blur(2px)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    padding: 20,
  },
  messagesContainer: {
    background: "#252729",
    flex: 1,
    overflow: "hidden",
  },
  buttonSpace: {
    width: 20,
  },
  title: {
    padding: 20,
    borderBottom: "solid 1px #FFF",
  },
  titleText: {
    color: colors.white,
    fontSize: 18,
  },
  actionButtons: {
    padding: 20,
  },
  messages: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    gap: 20,
    rowGap: 20,
  },
  message: {
    width: "32%",
    position: "relative",
    "@media(max-width: 1100px)": {
      width: "48%",
    },
    "@media(max-width: 700px)": {
      width: "100%",
    },
  },
  checkboxWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  checkbox: {
    color: colors.white,
    width: 30,
    height: 30,
  },
  checkboxInverse: {
    color: colors.black,
    width: 30,
    height: 30,
  },
});

export const MessagePicker = (props: IMessagePicker) => {
  const classes = useStyles();
  const ref = useRef<any>();
  const [bottom, setBottom] = useState(0);
  const [initialized, setInitialized] = useState(props.visible);
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const { visible } = props;
  const cardsPerRow = windowWidth < 700 ? 1 : windowWidth < 1100 ? 2 : 3;
  const cardHeight = windowWidth / cardsPerRow / 2;

  const messagesRows = props.messages.reduce((prev, current) => {
    if (
      !prev[prev.length - 1] ||
      prev[prev.length - 1].length === cardsPerRow
    ) {
      prev[prev.length] = [];
    }

    prev[prev.length - 1].push(current);

    return prev;
  }, []);

  useLayoutEffect(() => {
    setBottom(visible ? 0 : -(ref?.current?.offsetHeight || 0));
    if (visible) {
      setInitialized(true);
    }
  }, [visible]);

  const rowRenderer =
    (messagesRows: IMessage[][]) =>
    ({ index, style }) => {
      const messages = messagesRows[index] || [];

      return (
        <Box className={classes.messages} key={`row-${index}`} style={style}>
          {messages.map((message, messageIndex) => (
            <Box
              key={`row-${index}-${messageIndex}`}
              className={classes.message}
            >
              <BoardPreview
                characters={message.characters}
                boardStyle={props.boardStyle}
              />
              <Box className={classes.checkboxWrapper}>
                <MaterialCheckbox
                  checked={props.selected.includes(message.id)}
                  className={
                    props.boardStyle === "white"
                      ? classes.checkboxInverse
                      : classes.checkbox
                  }
                  color={"default"}
                  onChange={() => {
                    if (props.selected.includes(message.id)) {
                      props.setSelected(
                        props.selected.filter((item) => item !== message.id)
                      );
                    } else {
                      props.setSelected([...props.selected, message.id]);
                    }
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      );
    };

  return (
    <>
      <ButtonBase onClick={props.onCancel}>
        <div
          className={classes.cover}
          style={{
            display: props.visible ? "block" : "none",
          }}
        />
      </ButtonBase>
      <div
        ref={ref}
        className={classes.container}
        style={{
          opacity: initialized ? 1 : 0,
          bottom,
        }}
      >
        <Box className={classes.header}>
          <Box className={classes.title}>
            <Typography className={classes.titleText}>{props.title}</Typography>
          </Box>
          <Box className={classes.actionButtons}>
            <GroupedButton
              onClick={() => {
                props.setSelected(props.messages.map((message) => message.id));
              }}
            >
              Select All
            </GroupedButton>
            <GroupedButton
              onClick={() => {
                props.setSelected([]);
              }}
            >
              Clear
            </GroupedButton>
          </Box>
        </Box>
        <Box className={classes.messagesContainer}>
          <Padding>
            {!messagesRows?.length ? (
              <Body>{props.noMessages}</Body>
            ) : (
              <Box className={classes.messages}>
                <List
                  height={windowHeight}
                  rowCount={messagesRows.length + 1}
                  rowHeight={cardHeight}
                  width={windowWidth}
                  rowRenderer={rowRenderer(messagesRows)}
                />
              </Box>
            )}
          </Padding>
        </Box>
        <Box className={classes.buttons}>
          <Button buttonType="outline" onClick={props.onCancel} width={200}>
            Cancel
          </Button>
          <Box className={classes.buttonSpace} />
          <Button buttonType="white" onClick={props.onComplete} width={200}>
            Add
          </Button>
        </Box>
      </div>
    </>
  );
};
