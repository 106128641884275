import { ButtonBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode } from "react";

const useStyles = makeStyles({
  button: {
    backgroundColor: "rgba(80,80,80,0.5)",
    backdropFilter: "blur(2px)",
    padding: 24,
    paddingTop: 18,
    paddingBottom: 18,
    display: "flex",
    justifyContent: "space-between",
    width: 230,
    marginBottom: 1,

    "&:first-child": {
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
    },

    "&:last-child": {
      borderBottomLeftRadius: 7,
      borderBottomRightRadius: 7,
    },
  },
});

interface IFloatingAddButtonAction {
  children: string;
  onClick: () => void;
  icon?: ReactNode;
}

export const FloatingAddButtonAction = (props: IFloatingAddButtonAction) => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.button} onClick={props.onClick}>
      <Typography variant="body2">{props.children}</Typography>{" "}
      {props.icon || null}
    </ButtonBase>
  );
};
