import { IBoard, useToasts } from "@vestaboard/installables";
import { useCallback } from "react";
import { MESSAGE_SET_CREATOR_API } from "../constants";
import { IParams } from "../types";
import { useParams } from "./useParams";

interface IUseSend {
  characters: IBoard;
}

export const useSendMessage = () => {
  const { subscriptionConfigurationToken, subscriptionId } =
    useParams<IParams>();
  const toasts = useToasts();

  const exec = useCallback(
    async (input: IUseSend) => {
      const res = await fetch(
        `${MESSAGE_SET_CREATOR_API}/subscription/${subscriptionId}/message/send`,
        {
          method: "POST",
          body: JSON.stringify({
            characters: input.characters,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: subscriptionConfigurationToken,
          },
        }
      ).then((res) => res.json());

      toasts.setToastOffset(60);

      if (res?.status && res?.status !== 200) {
        toasts.addToast(res?.title || "Unable to send message", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        toasts.addToast("Message sent", {
          appearance: "success",
          autoDismiss: true,
        });
      }

      return res;
    },
    [subscriptionConfigurationToken, subscriptionId, toasts]
  );

  return [exec];
};
