import React from "react";
import { IBoard, IBoardStyle } from "@vestaboard/installables";
import { useState } from "react";
import { useGetColorTemplate } from "../hooks/useGetColorTemplate";
import { MessagePicker } from "../components/MessagePicker";

interface IColorTemplateSelector {
  boardStyle: IBoardStyle;
  setVisible: (visible: boolean) => void;
  setMessages: (messages: IBoard[]) => void;
  id: string;
  visible: boolean;
}

export const ColorTemplateSelector = (props: IColorTemplateSelector) => {
  const [selected, setSelected] = useState<string[]>([]);
  const { data } = useGetColorTemplate(props.id);

  const messages = (data?.colorTemplate.messages || []).map(
    (colorTemplate) => ({
      id: colorTemplate.id,
      characters: colorTemplate.characters as IBoard,
    })
  );

  return (
    <MessagePicker
      boardStyle={props.boardStyle}
      noMessages="There are no templates"
      visible={!!data?.colorTemplate?.id && props.visible}
      selected={selected}
      setSelected={setSelected}
      title={data?.colorTemplate?.title || "Templates"}
      messages={messages || []}
      onCancel={() => {
        props.setVisible(false);
        setSelected([]);
      }}
      onComplete={() => {
        if (!messages) {
          return;
        }

        props.setMessages(
          selected.map(
            (id) =>
              messages.find((message) => message.id === id)
                ?.characters as IBoard
          )
        );
        props.setVisible(false);
        setSelected([]);
      }}
    />
  );
};
