import { makeStyles } from "@mui/styles";

interface IFixedTabs {
  children: React.ReactNode;
  height: number;
}

const useStyles = makeStyles({
  container: ({ height }: { height: number }) => ({
    position: "fixed",
    height,
    left: 0,
    width: "100%",
    zIndex: 100,
    backgroundColor: `rgb(36, 40, 41)`,
    top: 0,
    borderBottom: "solid 1px #444",
  }),
  spaceSaver: ({ height }: { height: number }) => ({
    height,
    width: "100%",
  }),
});

export const FixedTabs = (props: IFixedTabs) => {
  const classes = useStyles({
    height: props.height,
  });

  return (
    <>
      <div className={classes.container}>{props.children}</div>
      <div className={classes.spaceSaver} />
    </>
  );
};
