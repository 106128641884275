import { useState } from "react";

const snakeToCamel = (str) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const useParams = <T>() => {
  const [params] = useState<T>(
    (window.location.search || "").includes("?")
      ? ((window.location.search || "")
          .split("?")[1]
          .split("&")
          .reduce((prev, current) => {
            const [key, value] = current.split("=");
            return {
              ...prev,
              [snakeToCamel(key)]: value,
            };
          }, {}) as T)
      : ({} as T)
  );

  return params;
};
