import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  fullScreen: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const FullScreenLoad = () => {
  const classes = useStyles();

  return (
    <Box className={classes.fullScreen}>
      <CircularProgress />
    </Box>
  );
};
