import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  IBoard,
  IBoardStyle,
  InstallableContainer,
  SlideUpMenu,
  Spacer,
} from "@vestaboard/installables";
import { useState } from "react";
import { ListColorTemplates } from "../components/ListColorTemplates";
import { ColorTemplateSelector } from "../components/ColorTemplateSelector";

interface ITemplates {
  visible: boolean;
  boardStyle: IBoardStyle;
  setVisible: (visible: boolean) => void;
  setMessages: (messages: IBoard[]) => void;
}

const useStyles = makeStyles({
  title: {
    fontSize: 18,
    textAlign: "center",
    color: "rgb(23 24 24)",
    fontWeight: "bold",
  },
});

export const Templates = (props: ITemplates) => {
  const [template, setTemplate] = useState<string | null>(null);
  const classes = useStyles();

  return (
    <>
      <SlideUpMenu
        visible={!template && props.visible}
        onClose={() => props.setVisible(false)}
      >
        <InstallableContainer pad>
          <Spacer size="extraLarge" />
          <Typography className={classes.title}>Templates</Typography>
          {props.visible ? (
            <ListColorTemplates
              onClick={(id) => {
                setTemplate(id);
              }}
            />
          ) : null}
          <Spacer size="extraLarge" />
        </InstallableContainer>
      </SlideUpMenu>
      {template ? (
        <ColorTemplateSelector
          boardStyle={props.boardStyle}
          id={template}
          setMessages={props.setMessages}
          setVisible={(visible) => {
            setTemplate(null);
            props.setVisible(visible);
          }}
          visible={props.visible}
        />
      ) : null}
    </>
  );
};
