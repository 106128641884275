import { Box, ButtonBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode } from "react";

interface ISlideUpButton {
  onClick: () => void;
  icon?: ReactNode;
  children: ReactNode;
}

const useStyles = makeStyles({
  buttonBase: {
    display: "flex",
    width: "100%",
  },
  button: {
    border: "solid 1px #000",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: 12,
    paddingLeft: 22,
    paddingRight: 22,
    borderRadius: 8,
    alignItems: "center",
  },
  typography: {
    color: "#000",
  },
});

export const SlideUpButton = (props: ISlideUpButton) => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.buttonBase} onClick={props.onClick}>
      <Box className={classes.button}>
        <Typography className={classes.typography}>{props.children}</Typography>
        {props.icon || null}
      </Box>
    </ButtonBase>
  );
};
