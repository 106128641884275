import { IBoard } from "@vestaboard/installables";
import { useCallback, useEffect, useState } from "react";
import { IError, IFavorite } from "../../../backend/types";
import { MESSAGE_SET_CREATOR_API } from "../constants";
import { IParams } from "../types";
import { useFormat } from "./useFormat";
import { useParams } from "./useParams";

function isError(res: IFavorite[] | IError): res is IError {
  return (res as IError).status !== undefined;
}

export const useGetFavorites = () => {
  const [data, setData] = useState<
    | {
        id: string;
        characters: IBoard;
      }[]
    | null
  >();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError | null>(null);
  const { subscriptionConfigurationToken, personId } = useParams<IParams>();
  const { format } = useFormat();

  const getFavorites = useCallback(() => {
    setLoading(true);
    setError(null);
    setData(null);

    fetch(`${MESSAGE_SET_CREATOR_API}/favorites/${personId}`, {
      headers: {
        Authorization: subscriptionConfigurationToken,
      },
    })
      .then((res) => res.json())
      .then(async (res: IFavorite[] | IError) => {
        setLoading(false);

        if (isError(res)) {
          setError(res);
        } else {
          const items = await Promise.all(
            res
              .filter((item) => item.text || item.characters)
              .map(async (item) => ({
                id: item.id,
                characters: item.characters
                  ? (JSON.parse(item.characters) as IBoard)
                  : await format(item?.text as string),
              }))
          );

          setData(items);
        }
      })
      .catch((err: IError) => {
        setLoading(false);
        setError(err);
      });
  }, [personId, subscriptionConfigurationToken, format]);

  useEffect(() => {
    getFavorites();
  }, [getFavorites]);

  return {
    data,
    loading,
    error,
    setData,
  };
};
