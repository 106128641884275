import {
  Button,
  InstallableContainer,
  SlideUpMenu,
  Spacer,
  createBoard,
} from "@vestaboard/installables";
import { SlideUpButton } from "./SlideUpButton";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ShareIllustration } from "./ShareIllustration";

interface IAddMessageMenu {
  toggled: boolean;
  setToggled: (toggled: boolean) => void;
  launch: (characters?: string) => void;
  setView: (view: string) => void;
}

const useStyles = makeStyles({
  title: {
    fontSize: 18,
    textAlign: "center",
    color: "rgb(23 24 24)",
    fontWeight: "bold",
  },
  importFromHistoryTitle: {
    color: "rgb(23 24 24)",
    fontSize: 14,
    paddingBottom: 18,
  },
  importFromHistoryDescription: {
    color: "rgb(125 135 142)",
    lineHeight: "24pt",
  },
  overflowBox: {
    overflow: "auto",
    maxHeight: "100vh",
  },
});

export const AddMessageMenu = ({
  toggled,
  setToggled,
  launch,
  setView,
}: IAddMessageMenu) => {
  const classes = useStyles();

  return toggled ? (
    <SlideUpMenu visible={toggled} onClose={() => setToggled(false)}>
      <Box className={classes.overflowBox}>
        <InstallableContainer pad>
          <Spacer size="extraLarge" />
          <Typography className={classes.title}>Add Messages</Typography>
          <Spacer size="large" />
          <SlideUpButton
            onClick={() => {
              setToggled(false);
              launch(JSON.stringify(createBoard()));
            }}
            icon={<img src="/icons/new-message.svg" alt="Plus" />}
          >
            Add Message Manually
          </SlideUpButton>
          <Spacer size="large" />
          <SlideUpButton
            onClick={() => {
              setToggled(false);
              setView("importCsv");
            }}
            icon={<img src="/icons/csv.svg" alt="CSV" />}
          >
            Import From Spreadsheet
          </SlideUpButton>
          <Spacer size="large" />
          <SlideUpButton
            onClick={() => {
              setToggled(false);
              setView("importFavorites");
            }}
            icon={<img src="/icons/favorites.svg" alt="Favorites" />}
          >
            Import From Favorites
          </SlideUpButton>
          <Spacer size="large" />
          <SlideUpButton
            onClick={() => {
              setToggled(false);
              setView("importTemplates");
            }}
            icon={<img src="/icons/templates.svg" alt="Templates" />}
          >
            Import From Templates
          </SlideUpButton>
          <Spacer size="extraLarge" />
          <Typography className={classes.importFromHistoryTitle}>
            Import From History
          </Typography>
          <Typography
            variant="body2"
            className={classes.importFromHistoryDescription}
          >
            To add messages from your history, tap any message you would like to
            add, click Share and select the Add to My Channel option.
          </Typography>
          <Spacer size="medium" />
          <ShareIllustration />
          <Spacer size="extraLarge" />
          <Button
            buttonType="outlineBlack"
            onClick={() => {
              setToggled(false);
            }}
          >
            Cancel
          </Button>
          <Spacer size="extraLarge" />
        </InstallableContainer>
      </Box>
    </SlideUpMenu>
  ) : null;
};
