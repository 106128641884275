import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import { Spacer } from "@vestaboard/installables";
import { useIconUpload } from "../hooks";
import Resizer from "react-image-file-resizer";
import { Image } from "@mui/icons-material";

interface IUploadButton {
  value?: string;
  setValue: (value?: string) => void;
}

const useStyles = makeStyles({
  buttonBase: {
    width: "100%",
  },
  box: {
    backgroundColor: "rgb(66 69 71)",
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    padding: 24,
    flexDirection: "column",
    width: "100%",
  },
  imageIcon: {
    fontSize: 28,
  },
  fileInput: {
    opacity: 0,
  },
  preview: {
    width: 100,
    height: 100,
  },
});

export const UploadButton = (props: IUploadButton) => {
  const { uploadIcon } = useIconUpload();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const fileRef = useRef<any>();

  return (
    <>
      <ButtonBase
        type="button"
        onClick={() => {
          fileRef.current.click();
        }}
        className={classes.buttonBase}
      >
        <Box className={classes.box}>
          {loading ? (
            <>
              <CircularProgress />
              <Spacer size="medium" />
              <Typography variant="body2">A 512x512 png works best.</Typography>
            </>
          ) : props.value ? (
            <>
              <img
                src={props.value}
                alt="Channel icon"
                className={classes.preview}
              />
              <Spacer size="medium" />
              <Typography variant="body2">A 512x512 png works best.</Typography>
            </>
          ) : (
            <>
              <Image className={classes.imageIcon} />
              <Spacer size="medium" />
              <Typography variant="body2">A 512x512 png works best.</Typography>
            </>
          )}
        </Box>
      </ButtonBase>
      <input
        ref={fileRef}
        type="file"
        className={classes.fileInput}
        onChange={async (e) => {
          if (e.target.files?.length) {
            setLoading(true);

            try {
              Resizer.imageFileResizer(
                e.target.files[0],
                512,
                512,
                "JPEG",
                80,
                0,
                async (file: File) => {
                  const url = URL.createObjectURL(file);
                  props.setValue(url);

                  const result = await uploadIcon(file);

                  props.setValue(result.url);
                  setLoading(false);
                },
                "file",
                512,
                512
              );
            } catch (err) {
              console.log(err);
            }
          }
        }}
        accept="image/*"
      />
    </>
  );
};
