import { IBoard } from "@vestaboard/installables";
import { useCallback } from "react";
import { vbml } from "@vestaboard/vbml";

export const useFormat = () => {
  const format = useCallback((text: string) => {
    // If the input is an array of arrays, don't send it through the VBML parser
    try {
      return JSON.parse(text) as IBoard;

      // Parse the string as VBML
    } catch (e) {
      return vbml.classic(text) as IBoard;
    }
  }, []);

  return {
    format,
  };
};
