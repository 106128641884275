import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode } from "react";

interface IScrollable {
  children: ReactNode;
}

const useStyles = makeStyles({
  scrollable: {
    overflow: "auto",
    height: `100vh`,
  },
});

export const Scrollable = (props: IScrollable) => {
  const classes = useStyles();
  return <Box className={classes.scrollable}>{props.children}</Box>;
};
