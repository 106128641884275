import { useState } from "react";
import { IError, ISubscriptionResult } from "../../../backend/types";
import { MESSAGE_SET_CREATOR_API } from "../constants";
import { IParams } from "../types";
import { useParams } from "./useParams";

interface IUseGetSubscription {
  variables: {
    id: string;
    title: string;
    description: string | null;
    icon: string | null;
  };
}

function isError(res: ISubscriptionResult | IError): res is IError {
  return (res as ISubscriptionResult).id === undefined;
}

export const useCreateSubscription = (): [
  (input: IUseGetSubscription) => Promise<ISubscriptionResult | IError>,
  { data: ISubscriptionResult | null; loading: boolean; error: IError | null }
] => {
  const { subscriptionConfigurationToken } = useParams<IParams>();
  const [data, setData] = useState<ISubscriptionResult | null>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IError | null>(null);

  const createSubscription = async (input: IUseGetSubscription) => {
    setLoading(true);
    setError(null);
    setData(null);

    const res = (await fetch(`${MESSAGE_SET_CREATOR_API}/subscription`, {
      method: "POST",
      body: JSON.stringify(input.variables),
      headers: {
        "Content-Type": "application/json",
        Authorization: subscriptionConfigurationToken,
      },
    }).then((res) => res.json())) as ISubscriptionResult | IError;

    setLoading(false);

    if (isError(res)) {
      setError(res);
    } else {
      setData(res);
    }

    return res;
  };

  return [
    createSubscription,
    { data: data as ISubscriptionResult, loading, error },
  ];
};
