import { useEffect, useState } from "react";

const getCardWidth = () => {
  const windowSize = window.innerWidth - 28;

  if (windowSize > 640) {
    return 640;
  }

  return windowSize;
};

export const useCardSize = () => {
  const [width, setWidth] = useState(640);

  useEffect(() => {
    setWidth(getCardWidth());

    const resize = () => {
      setWidth(getCardWidth());
    };

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return {
    height: width / 1.8,
    width,
  };
};
