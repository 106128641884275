import { Box, ButtonBase, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { ReactNode } from "react";

const useStyles = makeStyles({
  button: {
    position: "fixed",
    bottom: 20,
    right: 20,
    zIndex: 1100,
    backgroundColor: "rgba(80,80,80,0.5)",
    backdropFilter: "blur(2px)",
    padding: 24,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    width: 110,
  },
  toggled: {
    rotate: "0deg",
    transition: "rotate 0.25s",
  },
  addToggled: {
    rotate: "45deg",
  },
  menu: {
    position: "fixed",
    right: 20,
    bottom: 79,
    height: 0,
    overflow: "hidden",
    opacity: 0,
    transition: "height 0.25s, opacity 0.25s",
  },
  menuToggled: {
    opacity: 1,
    height: 250,
  },
});

interface IFloatingAddButton {
  children: ReactNode | ReactNode[];
  toggled: boolean;
  setToggled: (toggled: boolean) => void;
}

export const FloatingAddButton = (props: IFloatingAddButton) => {
  const classes = useStyles();
  const { toggled, setToggled } = props;

  return (
    <>
      <Box
        className={[
          classes.menu,
          toggled ? classes.menuToggled : classes.menu,
        ].join(" ")}
      >
        {props.children}
      </Box>
      <ButtonBase
        className={classes.button}
        onClick={() => setToggled(!toggled)}
      >
        <Typography variant="body2">Add</Typography>
        <Add
          className={[
            classes.toggled,
            toggled ? classes.addToggled : classes.toggled,
          ].join(" ")}
        />
      </ButtonBase>
    </>
  );
};
