import React from "react";
import {
  Padding,
  Theme,
  ToastProvider,
  VestaboardContextProvider as VestaboardContext,
  useVestaboardChannelContext,
} from "@vestaboard/installables";
import { useGetSubscription, useParams } from "./hooks";
import { IParams } from "./types";
import { TabView } from "./views/TabView";
import { ISubscriptionResult } from "../../backend/types";
import { FullScreenLoad } from "./components/FullScreenLoad";
import { EditingMessageProvider } from "./hooks/useEditingMessage";
import { Alert } from "@mui/material";

const VestaboardContextProvider = VestaboardContext as any;

export const AppComponent = (params: IParams) => {
  const { data, loading, error, setData } = useGetSubscription({
    variables: {
      id: params.subscriptionId,
    },
  });
  const config = useVestaboardChannelContext();

  const boardId = config?.data?.subscription?.board?.[0]?.id;

  if (loading || !boardId) {
    return <FullScreenLoad />;
  }

  if (error?.status === 401) {
    return <Alert severity="error">Invalid subscription</Alert>;
  }

  return (
    <ToastProvider>
      <TabView setData={setData} data={data as ISubscriptionResult} />
    </ToastProvider>
  );
};

export const App = () => {
  const params = useParams<IParams>();

  if (params.subscriptionId && params.subscriptionConfigurationToken) {
    return (
      <Theme>
        <EditingMessageProvider>
          <VestaboardContextProvider>
            <AppComponent {...params} />
          </VestaboardContextProvider>
        </EditingMessageProvider>
      </Theme>
    );
  }

  return (
    <Theme>
      <Padding>
        <Alert severity="error">
          Missing Subscription ID or Subscription Configuration Token
        </Alert>
      </Padding>
    </Theme>
  );
};
