import { useEffect, useState } from "react";

const QUERY = `query ListColorTemplates ($input: ListColorTemplateInputV2!) {
	listColorTemplates: listColorTemplatesV2(input: $input) {
		colorTemplates {
			id
			title
		}
	}
}`;

interface IData {
  listColorTemplates: {
    colorTemplates: {
      id: string;
      title: string;
    }[];
  };
}

let cache = null;

export const useListColorTemplates = () => {
  const [data, setData] = useState<IData | null>(cache);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (cache) {
      return setData(cache);
    }
    setLoading(true);
    fetch(process.env.REACT_APP_VESTABOARD_API, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        variables: {
          input: {},
        },
        query: QUERY,
        operationName: "ListColorTemplates",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.data?.listColorTemplates?.colorTemplates) {
          cache = json?.data;
          setData(json?.data);
        } else {
          setError("There was an error loading the color templates");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError(
          err?.message || "There was an error loading the color templates"
        );
        setLoading(false);
      });
  }, []);

  return { data, error, loading };
};
