import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface IShareIllustration {
  dark?: boolean;
}

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  shareButton: {
    display: "flex",
    background: "#3a3a3a",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
  },
  shareText: {
    color: "#FFF",
    fontSize: `12px !important`,
    marginRight: `5px !important`,
  },
  addButton: {
    display: "flex",
    background: "#FFF",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 14,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    border: "solid 1px #CCC",
  },
  addText: {
    color: "#181919",
    fontSize: `12px !important`,
    marginLeft: `5px !important`,
  },
  arrowInverted: {
    filter: `invert(100%)`,
    marginLeft: 8,
    marginRight: 8,
  },
  arrow: {
    marginLeft: 8,
    marginRight: 8,
  },
});

export const ShareIllustration = (props: IShareIllustration) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.shareButton}>
        <Typography className={classes.shareText}>Share</Typography>{" "}
        <img src="/icons/share.svg" alt="Share" />
      </Box>
      <img
        src="/icons/arrow.svg"
        alt="Arrow"
        className={props.dark ? classes.arrowInverted : classes.arrow}
      />
      <Box className={classes.addButton}>
        <img src="/icons/channel.svg" alt="Share" />
        <Typography className={classes.addText}>Add to My Channel</Typography>
      </Box>
    </Box>
  );
};
