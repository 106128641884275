import { useEffect, useState } from "react";

const QUERY = `query GetColorTemplate($id: String!) {
    colorTemplate: colorTemplateV2(id: $id) {
        __typename
        ... on ColorTemplateV2 {
            id
            title
            createdAt
            messages {
                id
                text
                characters
            }
        }
        ... on ColorTemplateErrorV2 {
            type
            error
        }
    }
}`;

interface IMessage {
  id: string;
  text: string;
  characters: Array<number[]>;
}

interface IData {
  colorTemplate: {
    id: string;
    title: string;
    createdAt: number;
    messages: IMessage[];
  };
}

export const useGetColorTemplate = (id: string) => {
  const [data, setData] = useState<IData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(process.env.REACT_APP_VESTABOARD_API, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        variables: {
          id,
        },
        query: QUERY,
        operationName: "GetColorTemplate",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        setData(json?.data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err?.message);
        setLoading(false);
      });
  }, [id]);

  return { data, error, loading };
};
