import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Spacer, SubTitle, createBoard } from "@vestaboard/installables";
import { NoMessagesButton } from "./NoMessagesButton";
import { ShareIllustration } from "./ShareIllustration";

interface INoMessage {
  launch: (characters?: string) => void;
  setView: (view: string) => void;
}

const useStyles = makeStyles({
  body: {
    fontSize: 16,
    lineHeight: "24px",
    color: "#7e878e",
  },
  icon: {
    filter: `invert(100%)`,
  },
  importFromHistoryTitle: {
    color: "#FFF",
    fontSize: 16,
    paddingBottom: 18,
  },
  importFromHistoryDescription: {
    color: "rgb(125 135 142)",
    lineHeight: "24pt",
    fontSize: 16,
  },
});

export const NoMessages = ({ setView, launch }: INoMessage) => {
  const classes = useStyles();

  return (
    <Box>
      <SubTitle>Get Started</SubTitle>
      <Spacer size="medium" />
      <Typography className={classes.body}>
        Build your own channel of recurring content. Add messages and set a
        frequency of your choosing.
      </Typography>
      <Spacer size="medium" />
      <Typography className={classes.body}>
        Create multiple channels for your Vestaboard with the option to upload
        your own channel name and icon from the Settings tab.
      </Typography>
      <Spacer size="extraLarge" />
      <SubTitle>Add Messages</SubTitle>
      <Spacer size="large" />
      <NoMessagesButton
        onClick={() => {
          launch(JSON.stringify(createBoard()));
        }}
        icon={
          <img
            src="/icons/new-message.svg"
            alt="Plus"
            className={classes.icon}
          />
        }
      >
        Add Message Manually
      </NoMessagesButton>
      <Spacer size="large" />
      <NoMessagesButton
        onClick={() => {
          setView("importCsv");
        }}
        icon={<img src="/icons/csv.svg" alt="CSV" className={classes.icon} />}
      >
        Import From Spreadsheet
      </NoMessagesButton>
      <Spacer size="large" />
      <NoMessagesButton
        onClick={() => {
          setView("importFavorites");
        }}
        icon={
          <img
            src="/icons/favorites.svg"
            alt="Favorites"
            className={classes.icon}
          />
        }
      >
        Import From Favorites
      </NoMessagesButton>
      <Spacer size="large" />
      <NoMessagesButton
        onClick={() => {
          setView("importTemplates");
        }}
        icon={
          <img
            src="/icons/templates.svg"
            alt="Templates"
            className={classes.icon}
          />
        }
      >
        Import From Templates
      </NoMessagesButton>
      <Spacer size="extraLarge" />
      <Typography className={classes.importFromHistoryTitle}>
        Import From History
      </Typography>
      <Typography
        variant="body2"
        className={classes.importFromHistoryDescription}
      >
        To add messages from your history, tap any message you would like to
        add, click Share and select the Add to My Channel option.
      </Typography>
      <Spacer size="extraLarge" />
      <ShareIllustration dark />
      <Spacer size="extraLarge" />
    </Box>
  );
};
