import React, { useState } from "react";
import { TabBar } from "@vestaboard/installables";
import { Messages } from "./Messages";
import { ISubscriptionResult } from "../../../backend/types";
import { Settings } from "./Settings";
import { Share } from "./Share";
import { FixedTabs } from "../components/FixedTabs";

interface ITabView {
  data: ISubscriptionResult | null;
  setData: (data: ISubscriptionResult | null) => void;
}

export const TabView = (props: ITabView) => {
  const [tab, setTab] = useState(0);
  return (
    <>
      <FixedTabs height={49}>
        <TabBar
          tabs={[
            {
              key: "messages",
              label: "Messages",
            },
            {
              key: "settings",
              label: "Settings",
            },
            {
              key: "share",
              label: "Share",
            },
          ]}
          fullWidth
          disableRipple={false}
          value={tab}
          setValue={setTab}
        />
      </FixedTabs>
      {tab === 0 ? (
        <Messages data={props.data} setData={props.setData} setTab={setTab} />
      ) : null}
      {tab === 1 ? (
        <Settings data={props.data} setData={props.setData} />
      ) : null}
      {tab === 2 ? <Share data={props.data} setData={props.setData} /> : null}
    </>
  );
};
