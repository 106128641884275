export const csvToArray = (strData: string) => {
  const strDelimiter = ",";

  const objPattern = new RegExp(
    "(\\" +
      strDelimiter +
      "|\\r?\\n|\\r|^)" +
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      '([^"\\' +
      strDelimiter +
      "\\r\\n]*))",
    "gi"
  );

  const arrData = [[]];

  let arrMatches = null;

  // @ts-ignore
  while ((arrMatches = objPattern.exec(strData))) {
    const strMatchedDelimiter = arrMatches[1];
    let strMatchedValue;

    // @ts-ignore
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      arrData.push([]);
    }

    if (arrMatches[2]) {
      // @ts-ignore
      strMatchedValue = arrMatches[2].replace(new RegExp('""', "g"), '"');
    } else {
      strMatchedValue = arrMatches[3];
    }

    // @ts-ignore
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  return arrData.map((item) => item[0]);
};
