import React from "react";
import { IBoard, IBoardStyle } from "@vestaboard/installables";
import { useState } from "react";
import { useGetFavorites } from "../hooks/useGetFavorites";
import { MessagePicker } from "../components/MessagePicker";

interface IFavorites {
  visible: boolean;
  boardStyle: IBoardStyle;
  setVisible: (visible: boolean) => void;
  setMessages: (messages: IBoard[]) => void;
}

export const Favorites = (props: IFavorites) => {
  const { visible, setVisible, setMessages } = props;
  const [selected, setSelected] = useState<string[]>([]);
  const { data } = useGetFavorites();

  return (
    <MessagePicker
      boardStyle={props.boardStyle}
      noMessages="You have no favorites"
      visible={visible}
      selected={selected}
      setSelected={setSelected}
      title="Favorites"
      messages={data || []}
      onCancel={() => {
        setVisible(false);
        setSelected([]);
      }}
      onComplete={() => {
        if (!data) {
          return;
        }

        setMessages(
          selected.map(
            (id) =>
              data.find((message) => message.id === id)?.characters as IBoard
          )
        );
        setVisible(false);
        setSelected([]);
      }}
    />
  );
};
