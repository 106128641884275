import { Box, ButtonBase, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef, useState } from "react";
import {
  Spacer,
  IBoard,
  useToasts,
  ToastProvider,
} from "@vestaboard/installables";
import { useFormat } from "../hooks";
import { csvToArray } from "../utils/csvToArray";

interface ICsvUploadButton {
  onChange: (messages: IBoard[] | null) => void;
}

const useStyles = makeStyles({
  buttonBase: {
    width: "100%",
  },
  box: {
    backgroundColor: "rgb(66 69 71)",
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    padding: 24,
    paddingTop: 14,
    paddingBottom: 14,
    flexDirection: "row",
    width: "100%",
  },
  boxInverse: {
    backgroundColor: "#FFF",
    color: "#000",
  },
  imageIcon: {
    fontSize: 28,
    marginRight: 14,
    filter: "invert(100%)",
  },
  imageIconInverse: {
    fontSize: 28,
    marginRight: 14,
  },
  fileInput: {
    opacity: 0,
  },
});

export const CsvUploadButton = (props: ICsvUploadButton) => {
  const classes = useStyles();
  const fileRef = useRef<any>();
  const { format } = useFormat();
  const [fileName, setFileName] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  return (
    <>
      <ToastProvider>
        <ButtonBase
          type="button"
          onClick={() => {
            fileRef.current.click();
          }}
          className={classes.buttonBase}
        >
          <Box
            className={[
              classes.box,
              ...(fileName ? [classes.boxInverse] : []),
            ].join(" ")}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <img
                  src="/icons/csv.svg"
                  className={
                    fileName ? classes.imageIconInverse : classes.imageIcon
                  }
                  alt="CSV"
                />
                <Spacer size="medium" />
                <Typography variant="body2">
                  {fileName ? fileName : "Choose .CSV file"}
                </Typography>
              </>
            )}
          </Box>
        </ButtonBase>
        <input
          ref={fileRef}
          type="file"
          accept=".csv"
          className={classes.fileInput}
          onChange={async (e) => {
            if (e.target.files?.length) {
              setLoading(true);
              const file = e.target.files[0];

              setFileName(file.name);

              const reader = new FileReader();

              reader.addEventListener("load", async () => {
                setLoading(true);

                try {
                  const csvArray = csvToArray((reader.result as string) || "");

                  const messages = csvArray.map(format);
                  props.onChange(messages);
                } catch (error) {
                  addToast("There was an error loading your CSV file", {
                    appearance: "error",
                  });
                }
                setLoading(false);
              });

              reader.addEventListener("error", () => {
                console.error(reader.error);
                addToast("There was an error loading your CSV file", {
                  appearance: "error",
                });
              });

              reader.readAsText(file);
              setLoading(false);
            }
          }}
        />
      </ToastProvider>
    </>
  );
};
