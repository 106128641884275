import "react-virtualized/styles.css";
import {
  InstallableContainer,
  Spacer,
  BoardPreview,
  BoardPreviewMask,
  IBoardStyle,
} from "@vestaboard/installables";
import { DragHandle } from "../components/DragHandle";
import { IMessage } from "../types";
import { DraggableProvided } from "react-virtualized";
import { useEffect } from "react";

export const RenderListMessage = ({
  message,
  provided,
  style,
  cardWidth,
  boardStyle,
  sendMessage,
  setMessages,
  messages,
  launch,
  setEditing,
  editing,
  isNative,
}: {
  message?: IMessage;
  provided: DraggableProvided;
  style: any;
  cardWidth: number;
  boardStyle: IBoardStyle;
  sendMessage: (input: any) => Promise<any>;
  setMessages: (messages: IMessage[]) => Promise<void>;
  messages: IMessage[];
  launch: (template?: string) => void;
  setEditing: (id: string | null) => void;
  editing: string | null;
  isNative?: boolean;
}) => {
  useEffect(() => {
    if (!isNative) {
      return;
    }

    if (editing === message?.id) {
      launch(JSON.stringify(message?.characters));
    }
  }, [editing, launch, message, isNative]);

  if (!message) {
    return null;
  }
  return (
    <div
      key={message?.id}
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...provided.draggableProps.style,
        ...style,
        paddingTop: 50,
      }}
      {...provided.dragHandleProps}
    >
      <InstallableContainer pad>
        <BoardPreviewMask
          handleSend={
            cardWidth > 500
              ? async () => {
                  await sendMessage({
                    characters: message.characters,
                  });
                }
              : undefined
          }
          isDraft
          boardId={message?.id}
          characters={message?.characters}
          handleEdit={() => {
            setEditing(message?.id);
            if (!isNative) {
              launch(JSON.stringify(message?.characters));
            }
          }}
          handleDiscard={() => {
            setMessages(
              messages.filter(
                (currentMessage) => message?.id !== currentMessage?.id
              )
            );
          }}
          handleDuplicate={() => {
            launch(JSON.stringify(message?.characters));
          }}
          topRightAction={<DragHandle showText={true} />}
        >
          <BoardPreview
            characters={message?.characters}
            boardStyle={boardStyle}
          />
        </BoardPreviewMask>
      </InstallableContainer>
      <Spacer size="large" />
    </div>
  );
};
