import { MESSAGE_SET_CREATOR_API } from "../constants";
import { IParams } from "../types";
import { useParams } from "./useParams";

interface ISignedResult {
  id: string;
  url: string;
}

export const useIconUpload = () => {
  const { subscriptionConfigurationToken } = useParams<IParams>();

  const uploadIcon = async (data: File) => {
    const signed = (await fetch(`${MESSAGE_SET_CREATOR_API}/icon`, {
      method: "POST",
      headers: {
        Authorization: subscriptionConfigurationToken,
      },
    }).then((res) => res.json())) as ISignedResult;

    await fetch(signed.url, {
      method: "PUT",
      body: data,
      headers: { "Content-Length": `${data.size || 0}` },
    }).then((res) => res.text());

    return {
      url: `${MESSAGE_SET_CREATOR_API}/icon/${signed.id}`,
      id: signed.id,
    };
  };

  return {
    uploadIcon,
  };
};
