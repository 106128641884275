import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ToggleButton } from "@vestaboard/installables";

const useStyles = makeStyles({
  toggle: {
    "& *": {
      textTransform: "none !important",
    },
  },
});

type IToggle = React.ComponentProps<typeof ToggleButton>;

export const Toggle = (props: IToggle) => {
  const classes = useStyles();
  return (
    <Box className={classes.toggle}>
      <ToggleButton {...props} />
    </Box>
  );
};
