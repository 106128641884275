import {
  InstallableContainer,
  Spacer,
  Button,
  IBoard,
  SubTitle,
  Padding,
  IBoardStyle,
  useToasts,
} from "@vestaboard/installables";
import { CsvUploadButton, Fixed } from "../components";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { IMessage } from "../types";
import { v4 as uuid } from "uuid";
import { MessagePicker } from "../components/MessagePicker";

interface IImportFromCsv {
  onViewChange: (view: string) => void;
  setMessages: (messages: IBoard[]) => void;
  boardStyle: IBoardStyle;
}

const useStyles = makeStyles({
  bodyText: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 16,
    lineHeight: "24px",
  },
  buttons: {
    display: "flex",
    padding: 20,
  },
});

export const ImportFromCsv = (props: IImportFromCsv) => {
  const [isReviewing, setIsReviewing] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [selected, setSelected] = useState<string[]>([]);
  const classes = useStyles();
  const { addToast } = useToasts();

  return (
    <>
      <InstallableContainer>
        <Padding>
          <Spacer size="extraLarge" />
          <SubTitle>Import from Spreadsheet</SubTitle>
          <Spacer size="large" />
          <Typography className={classes.bodyText}>
            If you've collected a list of messages you'd like to import at once,
            save the file in CSV format and upload below to import in bulk. Put
            your messages in column A cells with no header, title or any other
            information and less than 132 characters per row.
          </Typography>
          <Spacer size="extraLarge" />
          <CsvUploadButton
            onChange={(messages) => {
              if (!messages) {
                return;
              }

              const invalidMessages = messages.find(
                (message) => !Array.isArray(message)
              );

              if (invalidMessages) {
                addToast(`"${invalidMessages}" is in an invalid format.`, {
                  appearance: "error",
                });
                return;
              }

              setMessages(
                messages.map((characters) => ({
                  characters,
                  id: uuid(),
                }))
              );
            }}
          />
          <Spacer size="medium" />
          <Typography className={classes.bodyText}>
            You'll be able to preview and edit messages after the import is
            completed.
          </Typography>
        </Padding>
      </InstallableContainer>
      <Fixed orientation="bottom" height={90}>
        <InstallableContainer>
          <Box className={classes.buttons}>
            <Button
              type="button"
              buttonType="outline"
              onClick={() => props.onViewChange("setupMessages")}
            >
              Back
            </Button>
            <div style={{ width: 80 }} />
            <Button
              type="button"
              buttonType="white"
              disabled={!messages.length}
              onClick={() => {
                setIsReviewing(true);
              }}
            >
              Review
            </Button>
          </Box>
        </InstallableContainer>
      </Fixed>
      <MessagePicker
        boardStyle={props.boardStyle}
        noMessages="There are no messages"
        visible={isReviewing}
        selected={selected}
        setSelected={setSelected}
        title="Import"
        messages={messages || []}
        onCancel={() => {
          setIsReviewing(false);
          setSelected([]);
          setMessages([]);
          props.onViewChange("setupMessages");
        }}
        onComplete={() => {
          props.setMessages(
            selected.map(
              (id) =>
                messages.find((message) => message.id === id)
                  ?.characters as IBoard
            )
          );
          setIsReviewing(false);
          setSelected([]);
          props.onViewChange("setupMessages");
        }}
      />
    </>
  );
};
