import { DragHandleSharp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    position: "absolute",
    right: 16,
    top: 8,
    zIndex: 10,
    backgroundColor: "transparent",
    textTransform: "none",
    color: "white",
    cursor: "move",
  },
  typography: {
    margin: 8,
    fontSize: 12,
  },
});

interface IDragHandle {
  showText?: boolean;
}

export const DragHandle = (props: IDragHandle) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {props.showText ? (
        <Typography className={classes.typography}>
          Drag to change order
        </Typography>
      ) : null}
      <DragHandleSharp fontSize={"large"} />
    </Box>
  );
};
