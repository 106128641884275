import React, { createContext, useContext, useState } from "react";

interface IEditingMessageContent {
  editing: string | null;
  setEditing: (id: string | null) => void;
}

interface IEditingMessageProvider {
  children: React.ReactNode;
}

export const EditingMessageContext = createContext<IEditingMessageContent>({
  editing: null,
  setEditing: () => {},
});

export const useEditingMessage = () => {
  const { editing, setEditing } = useContext(EditingMessageContext);

  return { editing, setEditing };
};

export const EditingMessageProvider = ({
  children,
}: IEditingMessageProvider) => {
  const [editing, setEditing] = useState<string | null>(null);

  return (
    <EditingMessageContext.Provider value={{ editing, setEditing }}>
      {children}
    </EditingMessageContext.Provider>
  );
};
