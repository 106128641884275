import { useCallback, useEffect, useState } from "react";
import { ISubscriptionResult, IError } from "../../../backend/types";
import { MESSAGE_SET_CREATOR_API } from "../constants";
import { IParams } from "../types";
import { useParams } from "./useParams";

interface IUseGetSubscription {
  variables: {
    id: string;
  };
}

function isError(res: ISubscriptionResult | IError): res is IError {
  return (res as ISubscriptionResult).id === undefined;
}

export const useGetSubscription = (props: IUseGetSubscription) => {
  const [data, setData] = useState<ISubscriptionResult | null>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError | null>(null);
  const [id] = useState(props.variables.id);
  const { subscriptionConfigurationToken } = useParams<IParams>();

  const fetchSubscription = useCallback(
    async (retry) => {
      await fetch(`${MESSAGE_SET_CREATOR_API}/subscription/${id}`, {
        headers: {
          Authorization: subscriptionConfigurationToken,
        },
      })
        .then((res) => res.json())
        .then((res: ISubscriptionResult | IError) => {
          setLoading(false);

          if (isError(res)) {
            if (retry > 20) {
              setError(res);
            } else {
              setTimeout(() => {
                fetchSubscription(retry + 1);
              }, retry * 1000);
            }
          } else {
            setData(res);
          }
        })
        .catch((err: IError) => {
          if (retry > 20) {
            setLoading(false);
            setError(err);
          } else {
            setTimeout(() => {
              fetchSubscription(retry + 1);
            }, retry * 1000);
          }
        });
    },
    [id, subscriptionConfigurationToken]
  );

  useEffect(() => {
    setLoading(true);
    fetchSubscription(0);
  }, [fetchSubscription]);

  return {
    data,
    loading,
    error,
    setData,
  };
};
