import { Cadence } from "@vestaboard/installables";
import { useState } from "react";
import { IError, ISubscriptionResult } from "../../../backend/types";
import { MESSAGE_SET_CREATOR_API } from "../constants";
import { IParams } from "../types";
import { useParams } from "./useParams";

interface IUseUpdateSubscription {
  variables: {
    id: string;
    cadence: Cadence;
    sortOrder: string;
    title: string;
    description: string | null;
    icon?: string | null;
  };
}

function isError(res: ISubscriptionResult | IError): res is IError {
  return (res as ISubscriptionResult).id === undefined;
}

export const useUpdateSubscription = (): [
  (input: IUseUpdateSubscription) => Promise<ISubscriptionResult | IError>,
  { data: ISubscriptionResult | null; loading: boolean; error: IError | null }
] => {
  const [data, setData] = useState<ISubscriptionResult | null>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<IError | null>(null);
  const { subscriptionConfigurationToken } = useParams<IParams>();

  const update = async (input: IUseUpdateSubscription) => {
    setLoading(true);
    setError(null);
    setData(null);

    const res = (await fetch(
      `${MESSAGE_SET_CREATOR_API}/subscription/${input.variables.id}`,
      {
        method: "PUT",
        body: JSON.stringify({
          id: input.variables.id,
          cadence: input.variables.cadence,
          sortOrder: input.variables.sortOrder,
          title: input.variables.title,
          description: input.variables.description,
          icon: input.variables.icon,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: subscriptionConfigurationToken,
        },
      }
    ).then((res) => res.json())) as ISubscriptionResult | IError;

    setLoading(false);

    if (isError(res)) {
      setError(res);
    } else {
      setData(res);
    }

    return res;
  };

  return [update, { data: data as ISubscriptionResult, loading, error }];
};
