interface IFixed {
  orientation: "top" | "bottom";
  children: React.ReactNode;
  height: number;
  disablePlaceholder?: boolean;
}

export const Fixed = (props: IFixed) => {
  return (
    <>
      <div
        style={{
          position: "fixed",
          height: props.height,
          left: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: `rgba(36, 40, 41, 0.5)`,
          backdropFilter: "blur(7px)",
          ...(props.orientation === "top" ? { top: 0 } : { bottom: 0 }),
        }}
      >
        {props.children}
      </div>
      {!props.disablePlaceholder ? (
        <div
          style={{
            height: props.height,
            width: "100%",
          }}
        />
      ) : null}
    </>
  );
};
