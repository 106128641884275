import React from "react";
import { useListColorTemplates } from "../hooks/useListColorTemplates";
import { Alert } from "@mui/material";
import { SlideUpButton } from "./SlideUpButton";
import { Spacer } from "@vestaboard/installables";

interface IListColorTemplates {
  onClick(id: string): void;
}

export const ListColorTemplates = (props: IListColorTemplates) => {
  const { data, loading, error } = useListColorTemplates();

  if (error) {
    return (
      <>
        <Spacer size="large" />
        <Alert severity="error">
          There was an error loading the color templates
        </Alert>
      </>
    );
  }

  if (loading || !data) {
    return (
      <>
        <Spacer size="large" />
      </>
    );
  }

  return (
    <>
      <Spacer size="medium" />
      {data.listColorTemplates.colorTemplates.map((colorTemplate) => (
        <React.Fragment key={colorTemplate.id}>
          <Spacer size="large" />
          <SlideUpButton
            onClick={() => {
              props.onClick(colorTemplate.id);
            }}
          >
            {colorTemplate.title}
          </SlideUpButton>
        </React.Fragment>
      ))}
    </>
  );
};
