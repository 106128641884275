import { Box, CircularProgress, Typography, Alert } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Spacer,
  Button,
  InstallableContainer,
  SubTitle,
} from "@vestaboard/installables";
import React, { useEffect } from "react";
import { ISubscriptionResult } from "../../../backend/types";
import { useParams, useSubmit, useUnSubmit } from "../hooks";
import { Scrollable } from "../components/Scrollable";
import { IParams } from "../types";
import { MESSAGE_SET_CREATOR_API } from "../constants";

const useStyles = makeStyles({
  container: {
    backgroundColor: "rgba(125, 135, 142, 0.2)",
    padding: 32,
    marginTop: 48,
    borderRadius: 8,
  },
  bodyText: {
    color: "rgba(255,255,255,0.5)",
    fontSize: 16,
    lineHeight: "24px",
  },
  downloadCsv: {
    border: "solid 1px #FFF",
    color: "#FFF",
    textDecoration: "none",
    width: "100%",
    textAlign: "center",
    display: "block",
    fontSize: 16,
    fontFamily: '"HelveticaNeue","Roboto",sans-serif',
    paddingTop: 14,
    paddingBottom: 14,
    borderRadius: 2,
    fontWeight: 500,
    fontStyle: "normal",
  },
});

interface IShare {
  data: ISubscriptionResult | null;
  setData: (data: ISubscriptionResult | null) => void;
}

export const Share = (props: IShare) => {
  const classes = useStyles();
  const params = useParams<IParams>();
  const [submit, { loading, error, data }] = useSubmit();
  const [unSubmit, { loading: loading2, error: error2, data: data2 }] =
    useUnSubmit();
  const { setData } = props;

  useEffect(() => {
    if (data) {
      setData(data);
    }
  }, [data, setData]);

  useEffect(() => {
    if (data2) {
      setData(data2);
    }
  }, [data2, setData]);

  const csvName = `${(props.data?.title || "my-channel")
    .replace(/ /g, "-")
    .replace(/'/g, "")
    .replace(/"/g, "")
    .replace(/\./g, "")
    .replace(/,/g, "")
    .replace(/!/g, "")
    .toLowerCase()}.csv`;

  return (
    <Scrollable>
      <InstallableContainer pad>
        <Box className={classes.container}>
          {props.data?.submitted ? (
            <>
              <SubTitle>Channel Submitted</SubTitle>
              <Spacer size="large" />
              <Typography className={classes.bodyText}>
                Thanks for submitting your channel for consideration to be added
                as a new channel for other Vestaboard+ users to enjoy.
              </Typography>
              <Spacer size="large" />
              <Typography className={classes.bodyText}>
                Our team will follow up with next steps after reviewing the
                content you submitted.
              </Typography>
            </>
          ) : (
            <>
              <SubTitle>Submit Your Channel</SubTitle>
              <Spacer size="large" />
              <Typography className={classes.bodyText}>
                Want to inspire others with the content from this channel?
              </Typography>
              <Spacer size="large" />
              <Typography className={classes.bodyText}>
                Submit your channel to be considered for a new public
                Vestaboard+ channel for all users or export it to share with
                others.
              </Typography>
            </>
          )}

          <Spacer size="extraLarge" />
          {error ? (
            <>
              <Alert severity="error">
                There was an error submitting your message set
              </Alert>
              <Spacer size="large" />
            </>
          ) : null}
          {error2 ? (
            <>
              <Alert severity="error">
                There was an error revoking your message set submission
              </Alert>
              <Spacer size="large" />
            </>
          ) : null}
          <Button
            buttonType="outline"
            disabled={loading || loading2}
            onClick={async () => {
              if (!props?.data?.id) {
                return;
              }
              if (!!props?.data?.submitted) {
                await unSubmit({
                  variables: {
                    id: props.data.id,
                  },
                });
              } else {
                await submit({
                  variables: {
                    id: props.data.id,
                  },
                });
              }
            }}
          >
            {loading || loading2 ? (
              <CircularProgress />
            ) : props.data?.submitted ? (
              "Revoke Submission"
            ) : (
              "Submit to Vestaboard+"
            )}
          </Button>
        </Box>
        <Box className={classes.container}>
          <SubTitle>Export as CSV</SubTitle>
          <Spacer size="large" />
          <Typography className={classes.bodyText}>
            Exports a .CSV that can be imported elsewhere.
          </Typography>
          <Spacer size="extraLarge" />
          <a
            href={`${MESSAGE_SET_CREATOR_API}/subscription/${params.subscriptionId}/${params.subscriptionConfigurationToken}/${csvName}`}
            className={classes.downloadCsv}
            download={csvName}
            target="_blank"
            rel="noreferrer"
          >
            Export Message Set
          </a>
        </Box>
        <Spacer size="extraLarge" />
        <Spacer size="extraLarge" />
      </InstallableContainer>
    </Scrollable>
  );
};
