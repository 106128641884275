import { useEffect, useState } from "react";

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const useWindowSize = () => {
  const [{ width, height }, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setWindowSize(getWindowSize());

    const resize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return {
    height,
    width,
  };
};
